<template>
    <div>
        <CRow>
            <CCol class="col-12">
                <CCard>
                    <CCardHeader @click="searchFormCollapsed = !searchFormCollapsed" style="cursor: pointer;">
                        <span style="line-height: 35px"><strong>İletişim Mesajları</strong></span>
                        <div class="card-header-actions">
                            <CLink class="card-header-action btn-minimize">
                                <font-awesome-icon :icon="['fas', 'chevron-up']" size="lg" v-if="searchFormCollapsed"/>
                                <font-awesome-icon :icon="['fas', 'chevron-down']" size="lg" v-else/>
                            </CLink>
                        </div>
                    </CCardHeader>

                    <CCollapse :show="searchFormCollapsed">
                        <CCardBody>
                            <CForm @submit.prevent="searchContact">
                                <CRow>
                                    <CCol class="col-md-3 col-12">
                                        <CSelect
                                            label="Durum"
                                            :value.sync="searchForm.status"
                                            :options="searchStatusOptions"
                                        />
                                    </CCol>

                                    <CCol class="col-md-3 col-12">
                                        <CInput
                                            label="Müşteri Adı"
                                            v-model="searchForm.name"
                                        />
                                    </CCol>

                                    <CCol class="col-md-3 col-12">
                                        <CInput
                                            label="Müşteri Soyadı"
                                            v-model="searchForm.lastname"
                                        />
                                    </CCol>

                                    <CCol sm="12" md="2" class="mt-md-4">
                                        <CButton
                                            type="submit"
                                            color="info"
                                            class="px-3"
                                            style="margin-top: 4px"
                                        >Ara
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CForm>
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </CCol>

            <CCol md="12" v-if="gridShow">
                <CCard>
                    <CCardHeader>
                        <span><strong>Mesaj Listesi</strong></span>
                    </CCardHeader>
                    <CCardBody class="p-0">
                        <ag-grid-vue
                            style="width: 100%; height: 500px"
                            class="ag-theme-balham"
                            :columnDefs="columnDefs"
                            :defaultColDef="defaultColDef"
                            :rowData="rowData"
                            :sideBar="sideBar"
                            :getContextMenuItems="getGridContextMenuItems"
                            :rowSelection="rowSelection"
                            :enableRangeSelection="true"
                            :enableBrowserTooltips="true"
                            :statusBar="statusBar"
                            @rowDoubleClicked="onRowDoubleClicked"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

        <CModal
            title="Müşteri Mesajını Cevaplayın"
            size="lg"
            :closeOnBackdrop="false"
            :show.sync="modalVisible"
            class="carModalAddUpdateForm"
        >
            <CRow>
                <CCol class="col-12">
                    <h6><strong>Müşterinin Mesajı</strong></h6>
                    {{ modalForm.customerMessage }}
                </CCol>

                <CCol class="col-12 mt-4" v-show="modalMessageStatus == 'waiting'">
                    <h6><strong>Cevabınız</strong></h6>
                    <ckeditor v-model="modalForm.return.message" :config="editorConfig"></ckeditor>
                </CCol>

                <CCol class="col-12 mt-4" v-show="modalMessageStatus == 'been_answered'">
                    <h6><strong>Temsilci Cevabı ({{modalForm.return.user}})</strong></h6>
                    <span v-html="modalForm.return.message"></span>
                </CCol>
            </CRow>

            <template #footer>
                {{errText}}
                <CButton v-show="!modalSendButtonDisabled" color="success" :disabled="modalSendButtonDisabled || modalMessageStatus == 'been_answered' " @click="sendReturnMessage">Mesajı Gönder</CButton>
                <span v-show="modalSendButtonDisabled" class="spinner-border" role="status" aria-hidden="true"></span>
            </template>
        </CModal>
    </div>
</template>

<script>
import moment from "moment";
import Vue from "vue";
import {AgGridVue} from 'ag-grid-vue';
import axios from "axios";
import ckeditor from 'ckeditor4-vue'; // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html#basic-usage
Vue.use( ckeditor );

export default {
    name: "Search",
    components: {
        AgGridVue
    },
    data() {
        return {
            // MODAL START
            editorConfig:{

            },
            errText: '',
            modalVisible: false,
            modalSendButtonDisabled: false,
            modalMessageStatus: '',
            modalForm: {
                _id: '',
                email: '',
                customerMessage: '',
                return: {
                    message: '',
                    user: this.$store.state.GetServerData.name+" "+this.$store.state.GetServerData.lastname,
                },
                lang: 'tr'
            },
            // MODAL END

            // GRİD START
            gridShow: false,
            columnDefs: [
                {
                    field: "status",
                    headerName: "Durum",
                    cellRenderer: params => {
                        return params.value == 'waiting' ? 'Bekliyor' : 'Cevap Verildi';
                    },
                    cellStyle: params => {
                        return params.value == 'waiting' ? {color: '#e55353', fontWeight: 'bold'} : {
                            color: '#2eb85c',
                            fontWeight: 'bold'
                        }
                    },
                    width: 120
                },
                {
                    field: "name",
                    headerName: "Ad",
                    width: 100
                },
                {
                    field: "lastname",
                    headerName: "Soyad",
                    width: 100
                },
                {
                    field: "email",
                    headerName: "E-Posta",
                    width: 200
                },
                {
                    field: "dateTime",
                    cellRenderer: params => {
                        return moment(params.value).format("DD.MM.YYYY HH:mm")
                    },
                    headerName: "Mesaj Tarihi",
                    width: 140
                },
                {
                    field: "return.user",
                    headerName: "Cevap Veren",
                    width: 140
                },
                {
                    field: "return.dateTime",
                    headerName: "Cevap Tarihi",
                    cellRenderer: params => {
                        return params.value ? moment(params.value).format("DD.MM.YYYY HH:mm") : ''
                    },
                    width: 140
                },
                {
                    field: "message",
                    headerName: "Mesaj",
                    width: 500
                }
            ],
            defaultColDef: {
                sortable: true,
                resizable: true,
                filter: true
            },
            rowData: [],
            sideBar: { // Grid yan çubuk tanımlaması
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Sütunlar',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                    },
                    {
                        id: 'filters',
                        labelDefault: 'Filtreler',
                        labelKey: 'filters',
                        iconKey: 'filter',
                        toolPanel: 'agFiltersToolPanel',
                    }
                ],
                defaultToolPanel: ''
            },
            rowSelection: 'multiple', // Satır Seçim Türü
            statusBar: { // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
                statusPanels: [
                    {
                        statusPanel: 'agTotalAndFilteredRowCountComponent',
                        align: 'left'
                    },
                    {
                        statusPanel: 'agTotalRowCountComponent',
                        align: 'center'
                    },
                    {statusPanel: 'agFilteredRowCountComponent'},
                    {statusPanel: 'agSelectedRowCountComponent'},
                    {statusPanel: 'agAggregationComponent'}
                ]
            },
            // GRİD END


            // SEARCH FORM START
            searchFormCollapsed: false,
            searchStatusOptions: [
                {
                    value: '',
                    label: 'Tümü',
                },
                {
                    value: 'waiting',
                    label: 'Bekliyor',
                },
                {
                    value: 'been_answered',
                    label: 'Cevaplandı',
                }
            ],

            searchForm: {
                status: '',
                name: '',
                lastname: ''
            }
            // SEARCH FORM END

        }
    },
    watch:{
        'modalVisible':function (){
            this.errText = '';

            if(!this.modalVisible){
                this.modalFormClean();
            }
        }
    },
    methods: {
        modalFormClean(){
          this.modalMessageStatus = '';
          this.modalForm._id = '';
          this.modalForm.name = '',
          this.modalForm.lastname = '';
          this.modalForm.email = '';
          this.modalForm.customerMessage = '';
          this.modalForm.return = {message: '', user: this.$store.state.GetServerData.name+" "+this.$store.state.GetServerData.lastname,};
        },
        onGridReady(params) { // Grid ready olduğunda
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        },
        getGridContextMenuItems(params) {
            var _this = this;
            var result = [
                {
                    name: 'Cevapla',
                    action: function () {
                        _this.modalMessageStatus = params.node.data.status;
                        if(params.node.data.return){
                            _this.modalForm.return.message = params.node.data.return.message;
                            _this.modalForm.return.user = params.node.data.return.user;
                        }
                        _this.modalForm._id = params.node.data._id;
                        _this.modalForm.name = params.node.data.name;
                        _this.modalForm.lastname = params.node.data.lastname;
                        _this.modalForm.email = params.node.data.email;
                        _this.modalForm.customerMessage = params.node.data.message;
                        _this.modalForm.lang = params.node.data.lang;
                        _this.modalVisible = true;
                    },
                },
                {
                    name: 'Sil',
                    action: function () {
                        Vue.swal({
                            icon: 'error',
                            title: 'İletişim Formu Sil',
                            html: '<strong>' + params.node.data.name +" "+ params.node.data.lastname+'</strong> adındaki müşterinin iletişim formunu silmek istiyor musunuz ?',
                            showDenyButton: true,
                            confirmButtonText: 'Evet',
                            denyButtonText: 'Hayır',
                        }).then(result => {
                            if (result.isConfirmed) _this.contactDelete({_id: params.node.data._id});
                        });
                    },
                },
                {
                    name: 'Kopyala',
                    action: function () {
                        _this.gridApi.copySelectedRangeToClipboard();
                    },
                }
            ];
            return result;
        },
        onRowDoubleClicked(params) {
            this.modalMessageStatus = params.node.data.status;
            if(params.node.data.return){
                this.modalForm.return.message = params.node.data.return.message;
                this.modalForm.return.user = params.node.data.return.user;
            }
            this.modalForm._id = params.node.data._id;
            this.modalForm.name = params.node.data.name;
            this.modalForm.lastname = params.node.data.lastname;
            this.modalForm.email = params.node.data.email;
            this.modalForm.customerMessage = params.node.data.message;
            this.modalForm.lang = params.node.data.lang;
            this.modalVisible = true;
        },

        searchContact() {
            axios.get(process.env.VUE_APP_API_URL + 'admin/contact', {params: this.searchForm}).then(response => {
                this.rowData = response.data.contactList;
            });
            this.gridShow = true;
        },
        contactDelete(params) {
            this.$Progress.start();

            axios.delete(process.env.VUE_APP_API_URL + 'admin/contact/' + params._id)
                .then((response) => {
                    this.$Progress.finish();
                    this.searchContact();

                    Vue.swal({
                        icon: response.data.result,
                        html: response.data.message,
                        confirmButtonText: 'Tamam',
                    });
                });
        },

        sendReturnMessage(){
            if(this.modalMessageStatus == "waiting"){
                this.errText = '',
                    this.modalSendButtonDisabled = true;

                if(this.modalForm.return.message.length>0){

                    this.modalForm.return.dateTime = moment().format()

                    var form = {
                        _id: this.modalForm._id,
                        name : this.modalForm.name,
                        lastname : this.modalForm.lastname,
                        email: this.modalForm.email,
                        return: this.modalForm.return,
                        status: 'been_answered',
                        lang: this.modalForm.lang
                    }

                    axios.put(process.env.VUE_APP_API_URL + 'admin/contact',form).then(response => {
                        if(response.data.result == "success"){
                            this.modalVisible = false;
                            this.searchContact();
                        }

                        Vue.swal({
                            icon: response.data.result,
                            html: response.data.message,
                            confirmButtonText: 'Tamam'
                        });
                        this.modalSendButtonDisabled = false;
                    });
                }else{
                    this.modalSendButtonDisabled = false;
                    this.errText = "Cevap alanı boş olamaz."
                }
            }
        }
    },
    created:function()
    {
        this.searchContact();
    }
}
</script>

<style scoped>

</style>